import _objectSpread from "/Users/itnew000/Desktop/ofht20230915/htcode/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { sendThis } from '../../data/course/courseData';
export default {
  name: "CommonTable",
  props: ['tableKey', 'tableData', 'initTable', 'total'],
  data: function data() {
    return {
      centerDialogVisible: false,
      //是否显示确认弹框
      drawerData: null,
      //抽屉数据
      currentPage: parseInt(this.$route.query.currentPage ? this.$route.query.currentPage : 1),
      // 当前页数
      pageSize: parseInt(this.$route.query.pageSize ? this.$route.query.pageSize : 10),
      //每页个数
      dialog: {
        data: {}
      },
      //确认弹框数据
      isEdit: false,
      //默认是添加
      drawerKey: 0,
      //抽屉key
      drawerAll: {} //调用确认后的数据
    };
  },
  mounted: function mounted() {},
  created: function created() {
    for (var i = 0; i < this.initTable.list.length; i++) {
      var value = this.initTable.list[i];
      if (value.property) {
        var propertyArray = value.property.split(".");
        this.initTable.list[i].propertyArray = propertyArray;
      }
    }
    // console.log(this.tableData[0].course.courseName)
    // console.log(this.tableData[0][this.initTable.list[1].property])
  },

  methods: {
    // 页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      // 获取最大页数
      var pageMax = Math.ceil(this.total / this.pageSize);
      // 判断跳转页数是否大于最大页数，如果大于，跳转页数就等于最大页数
      if (this.currentPage > pageMax) {
        this.currentPage = pageMax;
      }
      var queryData = _objectSpread({}, this.$route.query);
      queryData.currentPage = this.currentPage;
      queryData.pageSize = page;
      this.$router.replace({
        path: this.$route.path,
        query: queryData
      });
    },
    // 页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      var queryData = _objectSpread({}, this.$route.query);
      queryData.currentPage = this.currentPage;
      queryData.pageSize = this.$route.query.pageSize ? this.$route.query.pageSize : 10;
      this.$router.replace({
        path: this.$route.path,
        query: queryData
      });
    },
    //操作按钮的点击
    clickFn: function clickFn(v, v2, row) {
      if (v2.dialog) {
        this.dialog = v2.dialog;
        this.dialog.data = this.$parent.dialogContent(this.dialog.data, row);
        this.centerDialogVisible = true;
        this.drawerAll = {
          v: v,
          v2: v2,
          row: row
        };
      } else {
        v2.clickFn && v2.clickFn(row);
      }
    },
    //确认弹框确定
    dialogConfirm: function dialogConfirm() {
      this.drawerAll.v2.clickFn(this.drawerAll.row);
    }
  }
};